import { FC, memo } from "react"
import { getCatalogPath } from "@/hooks/filterCatalog/helpers"
import { ICategoryTreeItem } from "@/types"
import { Link } from "@/ui/Link"
import { isParentCategory } from "@/utils/categories/helpers"
import {
  StyledCategoryLink,
  StyledCategoryName,
  StyledCategoryQty,
  StyledCategoryTitle,
} from "./StyledCategory"

const enum VIEW {
  CHILD = "child",
  MAIN = "main",
}

export const Title: FC<{
  parent?: number[]
  category: ICategoryTreeItem
  path: string
}> = memo(({ parent, category, path }) => {
  const variantView: VIEW = isParentCategory({
    parent: parent || [],
  })
    ? VIEW.MAIN
    : VIEW.CHILD

  return (
    <StyledCategoryTitle data-variant={variantView}>
      {variantView === VIEW.MAIN && (
        <StyledCategoryLink>
          <Link href={getCatalogPath(path)} scroll>
            {category.name}
          </Link>
        </StyledCategoryLink>
      )}
      {variantView === VIEW.CHILD && (
        <StyledCategoryLink>
          <Link href={getCatalogPath(path)} scroll>
            <StyledCategoryName>
              {category.name}{" "}
              <StyledCategoryQty>{category.product_qty}</StyledCategoryQty>
            </StyledCategoryName>
          </Link>
        </StyledCategoryLink>
      )}
    </StyledCategoryTitle>
  )
})

Title.displayName = "Title"
