import { BaseHTMLAttributes, FC } from "react"
import { SidebarInner, StyledSidebar } from "./StyledSidebar"

const Sidebar: FC<BaseHTMLAttributes<"div">> = ({ className, children }) => {
  return (
    <StyledSidebar className={className}>
      <SidebarInner>{children}</SidebarInner>
    </StyledSidebar>
  )
}

export default Sidebar
