import { FC, memo } from "react"
import { ICategoryTreeItem } from "@/types"
import { Category } from "./Category"
import { StyledList } from "../../../List/StyledList"

export type SubCategoryPropsType = {
  categories: ICategoryTreeItem[]
}

export const SubCategory: FC<SubCategoryPropsType> = memo(({ categories }) => {
  if (!categories.length) {
    return null
  }
  return (
    <StyledList as={"div"}>
      {categories.map((category) => {
        return <Category key={category.uuid || ""} category={category} />
      })}
    </StyledList>
  )
})

SubCategory.displayName = "SubCategory"
