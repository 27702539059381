import { cx } from "@linaria/core";
import { useQuery } from "react-query";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import type { FC } from "react";
import { fetchCategoriesByBusinessArea } from "@/api/catalogAPI";
import { BusinessAreasList } from "@/components/Catalog/Popup/BusinessAreasList";
import { useAppDispatch } from "@/hooks/redux";
import { setCategoriesByAreas } from "@/store/reducers/catalogSlice";
import { RootStateType } from "@/store/store";
import { Container, Row } from "@/styles/utils/StyledGrid";
import { cssIsActive } from "@/styles/utils/Utils";
import { BaseLoader } from "@/ui/Loaders/BaseLoader/BaseLoader";
import { scrollBodyDisable, scrollBodyEnable } from "@/utils/common/helpers";
import { CategoriesContainer, StyledCatalogPopup } from "./StyledPopup";
import Sidebar from "../../Sidebar/Sidebar";
import { cssSidebarGray } from "../../Sidebar/StyledSidebar";
import Categories from "../Categories/Categories";
export type CatalogPopupPropsType = Pick<RootStateType["catalog"], "categoriesByAreas"> & Pick<RootStateType["catalog"]["popup"], "isShow"> & Pick<RootStateType["categories"], "categories" | "businessAreas"> & {
  offsetLayer?: number;
};
const Popup: FC<CatalogPopupPropsType> = memo(({
  isShow,
  categoriesByAreas: categoriesByBusinessArea,
  categories: categoriesInState,
  businessAreas,
  offsetLayer = 0
}) => {
  const dispatch = useAppDispatch();
  const [currentBusinessAreaId, setCurrentBusinessAreaId] = useState<number | null>(null);
  const [currentBusinessAreaUuid, setCurrentBusinessAreaUuid] = useState<string | null>(null);
  const categoriesAreaRef = useRef<HTMLDivElement | null>(null);
  const {
    isFetching
  } = useQuery(["categoriesByBusinessArea", currentBusinessAreaUuid], () => fetchCategoriesByBusinessArea(currentBusinessAreaUuid), {
    enabled: !!currentBusinessAreaUuid,
    onSuccess: data => {
      dispatch(setCategoriesByAreas({
        categoryByAreas: data || undefined,
        categoriesFetched: categoriesInState?.fetched || null
      }));
    }
  });
  const updateBAIdentifiers = (id: number | null, uuid: string | null) => {
    setCurrentBusinessAreaId(id);
    setCurrentBusinessAreaUuid(uuid);
  };
  const categories = useMemo(() => currentBusinessAreaId === null ? categoriesInState?.treeSorted || [] : categoriesByBusinessArea, [categoriesInState?.treeSorted, categoriesByBusinessArea, currentBusinessAreaId]);
  const popupRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!isShow) {
      scrollBodyEnable();
    } else {
      scrollBodyDisable();
    }
  }, [isShow]);
  return <StyledCatalogPopup offset={offsetLayer} className={cx(isShow && cssIsActive)} ref={popupRef}>
        <Container>
          <Row>
            <CategoriesContainer>
              {isFetching && <BaseLoader />}

              <Sidebar className={cssSidebarGray}>
                <BusinessAreasList updateIdentifiers={updateBAIdentifiers} businessAreas={businessAreas} currentId={currentBusinessAreaId} />
              </Sidebar>

              <Categories categories={categories} variant={"popup"} ref={categoriesAreaRef} isShowImage={!!currentBusinessAreaUuid} />
            </CategoriesContainer>
          </Row>
        </Container>
      </StyledCatalogPopup>;
});
Popup.displayName = "Popup";
export { Popup };