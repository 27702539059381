import { FC, memo } from "react";
import { StyledList, StyledListItem } from "@/components/List/StyledList";
import { RootStateType } from "@/store/store";
import { cssIsActive } from "@/styles/utils/Utils";
import { Typography } from "@/ui/Typography/Typography";
import { Category } from "../../../../../contracts";
export type BusinessAreasListPropsType = Pick<RootStateType["categories"], "businessAreas"> & {
  currentId: number | null;
  updateIdentifiers: (id: number | null, uuid: string | null) => void;
};
const BusinessAreasList: FC<BusinessAreasListPropsType> = memo(({
  businessAreas,
  updateIdentifiers,
  currentId
}) => {
  const updateHandle = (area: Category) => {
    updateIdentifiers(area.id || null, area.uuid || null);
  };
  return <StyledList>
        <StyledListItem className={currentId === null ? cssIsActive : ""}>
          <Typography variant={"p14"} onClick={() => updateIdentifiers(null, null)}>
            Все сферы деятельности
          </Typography>
        </StyledListItem>
        {businessAreas?.map(area => <StyledListItem key={area.id} className={!!currentId && currentId === area.id ? cssIsActive : ""} onClick={() => updateHandle(area)}>
            <Typography variant={"p14"}>{area.name}</Typography>
          </StyledListItem>)}
      </StyledList>;
});
BusinessAreasList.displayName = "BusinessAreasList";
export { BusinessAreasList };